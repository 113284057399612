<template>
    <div>
        <v-card>
            <v-card-text>
                <div class="text-subtitle-1 d-flex justify-space-between">
                    <div>Mortalidad {{year}}</div>
                    <page-year
                        :year.sync="year"
                        :maxYear="2015"
                    />
                </div>
                <apexchart
                    type='bar'
                    :options="options"
                    :series="series"
                />
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
    mounted(){
        this.year = this.moment(new Date()).format('YYYY')
    },
    components:{
        'apexchart':VueApexCharts,
        'page-year':()=>import('@/components/com/PageYear'),
    },
    data:()=>({
        year:2022,
        loading:false,
        consultas:[],
        meses:[
            'Ene','Feb','Mar','Abr','May','Jun',
            'Jul','Ago','Sep','Oct','Nov','Dic',
        ],
        options:{},
        series:[],
    }),
    methods:{
        async carga(){
            this.loading=true
            try{
                const req = await this.$http.get('/dashboard/listMortalidadYear',{params:{fecha:this.year}})
                this.consultas=req.data.root

                let t1 = [...req.data.root].filter(egr=>parseInt(egr.tiempo) > 2)
                let t2 = [...req.data.root].filter(egr=>parseInt(egr.tiempo) <= 2)

                let total1 = []
                let total2 = []

                total1.push([...t1].filter(egr=>egr.month =='01').length)//01
                total1.push([...t1].filter(egr=>egr.month =='02').length)//01
                total1.push([...t1].filter(egr=>egr.month =='03').length)//01
                total1.push([...t1].filter(egr=>egr.month =='04').length)//01
                total1.push([...t1].filter(egr=>egr.month =='05').length)//01
                total1.push([...t1].filter(egr=>egr.month =='06').length)//01
                total1.push([...t1].filter(egr=>egr.month =='07').length)//01
                total1.push([...t1].filter(egr=>egr.month =='08').length)//01
                total1.push([...t1].filter(egr=>egr.month =='09').length)//01
                total1.push([...t1].filter(egr=>egr.month =='10').length)//01
                total1.push([...t1].filter(egr=>egr.month =='11').length)//01
                total1.push([...t1].filter(egr=>egr.month =='12').length)//01

                total2.push([...t2].filter(egr=>egr.month =='01').length)//01
                total2.push([...t2].filter(egr=>egr.month =='02').length)//01
                total2.push([...t2].filter(egr=>egr.month =='03').length)//01
                total2.push([...t2].filter(egr=>egr.month =='04').length)//01
                total2.push([...t2].filter(egr=>egr.month =='05').length)//01
                total2.push([...t2].filter(egr=>egr.month =='06').length)//01
                total2.push([...t2].filter(egr=>egr.month =='07').length)//01
                total2.push([...t2].filter(egr=>egr.month =='08').length)//01
                total2.push([...t2].filter(egr=>egr.month =='09').length)//01
                total2.push([...t2].filter(egr=>egr.month =='10').length)//01
                total2.push([...t2].filter(egr=>egr.month =='11').length)//01
                total2.push([...t2].filter(egr=>egr.month =='12').length)//01

                this.options={
                    chart:{
                        stacked: true,
                    },
                    colors: ['#F00', '#FFBF00'],
                    tooltip: {
                        enabled:true,
                    },
                    xaxis: {
                        categories: this.meses,
                    },
                    yaxis:{
                        min: 0,
                        decimalsInFloat: 0,
                    },
                    dataLabels: {
                        formatter(val){
                            return(val)
                        },
                    },
                }

                this.series=[
                    {
                        name: "Ajustada",
                        data:total1
                    },
                    {
                        name: "Menor a 48h",
                        data:total2
                    },
                ]
                this.loading=false
            } catch(err) {
                this.loading=false
                this.$store.dispatch('security/revisaError',err)
            }
        },
    },
    watch:{
        year(){
            this.carga()
        }
    },
}
</script>

<style>

</style>